import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles"


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pacifico: {
      fontFamily: 'Pacifico',
      color: `${theme.palette.primary.main}`,
      textAlign: 'center',
      lineHeight: 1.56,
    },
    light: {
      fontWeight: 300,
      lineHeight: 1.56,
    },
    lightCentered: {
      textAlign: 'center',
      fontWeight: 300,
      lineHeight: 1.56,
    },
    justify: {
      textAlign: 'justify',
      fontWeight: 400,
      lineHeight: 1.77,
    },
    lightJustify: {
      textAlign: 'justify',
      fontWeight: 300,
      lineHeight: 1.56,
    },
    regCentered: {
      textAlign: 'center',
      fontWeight: 400,
      lineHeight: 1.56,
    },
  }),
)
